.header {
    @extend .flex;
    @extend .align-center;
    padding: 10px 10px;
    justify-content: space-between;
    background-color: $color-white;
    box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.25);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    transition: top 0.5s;

    .menu {
        cursor: pointer;
    }

    a {
        color: var(--black-color);
        display: block;

        &:hover {
            color: var(--black-color);
            text-decoration: none;
            cursor: pointer;
        }
    }
}

.active-link {
    background-color: rgba(28, 69, 70, 0.1);
    box-shadow: 0 4px 10px rgba(28, 69, 70, 0.3);
    border-radius: 5px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    padding: 5px
}


.hide {
    @extend .flex;
    @extend .align-center;
    padding: 10px 10px;
    justify-content: space-between;
    background-color: $color-white;
    box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.25);
    position: fixed;
    top: -500px;
    right: 0;
    left: 0;
    z-index: 10;
    transition: top 0.5s;

    .menu {
        cursor: pointer;
    }
}

.notifiction-icon {
    margin-left: 10px;
}

.sidebar {
    position: fixed;
    height: 260px;
    width: 300px;
    display: none;
    top: 70px;
    right: 0;
    bottom: 65px;
    z-index: 2;
    background-color: var(--white-color);
    padding: 20px;

    >div {
        height: 100%;
        flex-direction: column;
    }

    a {
        color: var(--black-color);
        display: block;
        margin: 15px 0;

        &:hover {
            color: var(--black-color);
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .button {
        padding: 10px;
        border-radius: 10px;
    }
}

// // // // // //
.mobilemenu {
    display: none;

    span {
        margin: 5px;
        display: block;
        height: 2px;
        width: 20px;
        background-color: black;
    }
}


@media (max-width: 1023px) {
    .nav-bar {
        display: none;
    }

    .mobilemenu {
        display: block;
    }

    .sidebar {
        display: block;
    }
}