.new-user {
  padding: 10px 30px;
  border-bottom: 1px solid #9c9c9c;
  .button {
    // font-size: 24px;
    font-weight: 400;
    padding: 10px 20px;
    border-radius: 10px;
    width: auto;
    background-color: #30a953;
    border-color: #30a953;
    // text-transform: uppercase;
  }
}
.user-search-bar {
  padding: 10px 30px;
  border-bottom: 1px solid #9c9c9c;
  align-items: center;
  input {
    border: 0;
  }
}
// Modal Popup
.modal-popup {
  position: fixed;
  top: 45%;
  transform: translate(-50%, -50%);
  left: 50%;
  right: 0;
  width: 60%;
  height: 70vh;
  background-color: var(--white-color);
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.25);
  z-index: 99;
  opacity: 0.90;
  padding: 50px 20px 10px;
  overflow: auto;
  border: 1px solid #9c9c9c;
  .modal-box {
    padding-top: 40px;
    padding-bottom: 20px;
    height: calc(60vh - 105px);
    // padding: 0 20px;
    overflow: auto;
    .btns {
      margin-top: 50px;
    }
  }
  .close {
    margin-right: 10px;
  }
  p {
    margin-bottom: 0;
    padding: 15px 10px;
  }
  .modal-box {
    > div.flex {
      // border: 1px solid #b5b5b5;
      > div {
        width: 50%;
      }
    }
    .right-modal {
      .modal-head {
        border: 1px solid #b5b5b5;
        padding: 13px 20px;
      }
    }
    .right-body {
      border-top: 0;
      padding: 5px;
      border: 1px solid #b5b5b5;
      > ul > li {
        + li {
          margin-top: 20px;
        }
        ul li {
          border-bottom: 1px solid #00000040;
          padding: 5px 10px;
        }
      }
    }
  }
  .btns {
    a {
      + a {
        margin-left: 20px;
        color: var(--black-color);
      }
    }
  }
  // dl {
  //   margin-top: 0;
  //   margin-bottom: 20px;
  // }
  // dt {
  //   width: 40%;
  //   display: inline-block;
  // }
  // dd {
  //   width: 60%;
  //   display: inline-block;
  //   margin-left: 0;
  //   input {
  //       margin-top: 0;
  //       margin-bottom: 0;
  //   }
  //   label {
  //       padding-right: 20px;
  //   }
  // }
  .p-multiselect {
    // border: 0;
    border-color: #b5b5b5;
    border-radius: 0;
  }
}
.user-heading {
  li {
    padding: 0;
    span {
      margin-left: 40px;
    }
    + li {
      margin-left: 30px;
      &:before {
        content: "";
        display: inline-block;
        width: 29px;
        height: 29px;
        margin-right: 20px;
        background-image: url("../images/breadcrumb.png");
      }
      span {
        margin-left: 0;
      }
    }
  }
}
.type .dropdown {
  z-index: 9;
}
.add-patners {
  // max-width: 1000px;
  // margin: auto;
  .alert-name {
    padding-left: 24px;
  }
  .form-field {
    border: 0;
    padding-bottom: 10px;
  }
  .button {
    margin: 20px;
    width: auto;
    padding: 5px 20px;
    border-radius: 15px;
  }
  .advertisers {
    border-top: 1px solid #b5b5b5;
    padding: 50px;
    position: relative;
    z-index: 0;
    p {
      margin-top: 20px;
    }
  }
  .table {
    margin: auto;
    .alert-body-name {
      // width: 480px;
      padding-left: 20px;
    }
    .alert-body {
      .index {
        padding: 10px 0;
        box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.25);
      }
      li {
        > .flex {
          .alert-body-id {
            width: 200px;
            justify-content: flex-start;
            padding-left: 20px;
          }
          .alert-body-add {
            width: 280px;
            @extend .flex;
            align-items: center;
            vertical-align: middle;
          }
        }
      }
    }
  }
}

.overflow-ellipsis {
  max-width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}