.container-login {
    display: flex;
    justify-content: space-between;
    // margin-bottom: 1.25rem;
}

.login {
    @extend .flex;
    // @extend .justify-center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    background-color: #EFEFEF;
    background-image: url("../images/Polygon 1.png");
    background-size: 100%;
    background-repeat: no-repeat;
}

.login-box {
    margin-left: 110px;
    padding: 25px 20px;
    min-width: 444px;
    border: 4px solid #9C9C9C;
    background-color: var(--white-color);
    border-radius: 20px;

    // .button {
    //     margin-top: 46px;
    // }
    iframe {
        width: 100% !important;
        max-width: 250px !important;
        margin: 0 auto !important;
    }
}

.already-user {
    text-align: center;
    margin-top: 20px;
    @extend .mt-12;

    a {
        margin-left: 12px;
    }
}

.log-in-btn {
    img {
        margin-right: 12px;
    }
}

.password {
    position: relative;

    span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;

        img {
            width: 20px;
            height: 20px;
        }
    }
}

/* Rotation for outer ring */
@keyframes rotateOuterClockwise {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Rotation for inner ring - clockwise */
@keyframes rotateInnerClockwise {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Rotation for inner ring - counterclockwise */
@keyframes rotateInnerCounterClockwise {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-360deg);
    }
}

/* Smooth transition to stop when hover is removed */
.outer-ring,
.inner-ring :hover::after {
    transition: transform 5s ease-out;
}

hover::after {
    background: #081d96;
}
/* On hover of outer ring, rotate only outer ring */
.outer-ring:hover {
    animation: rotateOuterClockwise 20s linear infinite;
}

/* On hover of inner ring, rotate inner components in different directions */
.inner-ring:hover .inner-clockwise {
    animation: rotateInnerClockwise 20s linear infinite;
}

.inner-ring:hover .inner-counterclockwise {
    animation: rotateInnerCounterClockwise 20s linear infinite;
}

@media only screen and (max-width: 1280px) {
    .login-box {
        margin-left: 70px;
        padding: 15px 17px;
        min-width: 350px;
    }

    .already-user {
        margin-top: 20px;
    }
}